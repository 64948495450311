import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private defaultTitle = '';
  private showBut = false;
  private titleSubject: BehaviorSubject<string> = new BehaviorSubject(
    this.defaultTitle
  );
  private showButton: BehaviorSubject<boolean> = new BehaviorSubject(
    this.showBut
  );
  public title: Observable<string>;
  public dispBut: Observable<boolean>;

  constructor(private titleService: Title) {
    this.title = this.titleSubject.asObservable();
    this.dispBut = this.showButton.asObservable();
  }

  public setTitle(title: string) {
    this.titleSubject.next(title);
  }

  public disableButton(val: boolean) {
    this.showButton.next(val);
  }
}
